import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Gear, Key } from "phosphor-react";
import { ModalMensagePrivate } from "../modalMensagePrivate/index.js";

export const MenuCostumers = (props) => {
  return (
    <>
      <Menu>
        <MenuButton
          colorScheme="white"
          icon={<Gear size={40} color="#aeaeae" weight="duotone" />}
          as={IconButton}
        />

        <MenuList>
          <MenuItem>
            <ModalMensagePrivate />
          </MenuItem>
          <MenuItem
            onClick={props.onClick}
            icon={<Key size={40} color="#aeaeae" weight="duotone" />}
          >
            Bloquear/Liberar
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
