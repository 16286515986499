import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  let navigate = useNavigate();

  useEffect(() => {
    const status = Cookies.get("email") !== undefined ? true : false;

    if (status === false) {
      navigate("/login");
    }
  }, []);
};

export default useAuth;
