/* eslint-disable no-unused-vars */
import { useState } from "react";
import * as Styles from "./style.js";
import { TrashSimple, UserGear } from "phosphor-react";
import { Spinner } from "@chakra-ui/react";
import { ModalEditUser } from "../modalEdit/index.js";
import Axios from "axios";
import { ModalActionConfirm } from "../../../../components/modalConfirm/index.js";

export const UserCard = (props) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const data = props.data;
    await Axios.post(`${process.env.REACT_APP_URL_API}/deleteUser`, {
      id: data.id,
    });
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  return (
    <Styles.MainContainer>
      <Styles.UserHeader>
        <div>
          Nome: <strong>{props.name}</strong>
        </div>
        <UserGear size={40} color="black" weight="duotone" />
      </Styles.UserHeader>
      <Styles.UserBody>
        <div className="Icon">
          <ModalEditUser data={props.data} />
        </div>
        <div className="Icon">
          <ModalActionConfirm
            onConfirm={handleDelete}
            icon={<TrashSimple size={40} color="black" weight="duotone" />}
            label="Tem certeza que deseja deletar o usuário?"
          />
        </div>
        {!!loading && <Spinner />}
      </Styles.UserBody>
    </Styles.MainContainer>
  );
};
