import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./screen/home/index.js";
import { Docs } from "./screen/documents/index.js";
import { Dashboard } from "./screen/dashboard/index.js";
import { Content } from "./screen/content/index.js";
import { Login } from "./screen/login/index.js";
import { Users } from "./screen/user/index.js";
import { Licenses } from "./screen/licenses/index.js";

export const RoutesAuth = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/documents" element={<Docs />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/content" element={<Content />} />
        <Route path="/users" element={<Users />} />
        <Route path="/licenses" element={<Licenses />} />
      </Routes>
    </Router>
  );
};
