import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100vh;
  background: #f1f1f1;
  display: flex;
  flex-direction: row;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 2rem;
`;

export const Text = styled.div`
  ${(props) => props}
`;

export const Input = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const TableSpace = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 100%;
  background: #d9d9d9;
  border-radius: 1rem;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Table = styled.div`
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const TableArrows = styled.div`
  padding: 10px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
`;
