import styled from "styled-components";

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 80px;
  border-radius: 1rem;
  background-color: #f1f1f1;
  padding: 15px;
`;

export const Info = styled.div`
  width: 80%;
  max-height: 3.5rem;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  ${(props) => props}
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  cursor: pointer;
  width: 3rem;
  height: 3rem;

  &:hover {
    border-radius: 0.5rem;
    background: #d9d9d9;
  }

  &:last-child {
    margin-left: 1rem;
  }
`;
