import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { InputData } from "../../../../components/input/index.js";
import { useState } from "react";
import { SelectModule } from "../../../../components/select/index.js";
import { PencilSimple } from "phosphor-react";
import Axios from "axios";
import { MessageError } from "../../../../components/messageError/index.js";

export const ModalEdit = (props) => {
  const [handleModal, setHandleModal] = useState(false);
  const [data, setData] = useState(props.data);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateContent = async () => {
    setLoading(true);
    if (
      data.title !== "" &&
      data.description !== "" &&
      data.video !== "" &&
      data.idModule !== ""
    ) {
      await Axios.post(`${process.env.REACT_APP_URL_API}/updateContent`, {
        id: data.id,
        title: data.title,
        description: data.description,
        video: data.video,
        idModule: data.idModule,
      });
      setHandleModal(false);
      setError(false);
      setLoading(false);
      setData([]);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const CallModal = () => {
    if (handleModal === false) {
      setHandleModal(true);
    } else {
      setHandleModal(false);
      setData(props.data);
    }
  };

  return (
    <>
      <Styles.Icon onClick={CallModal}>
        <Tooltip label="Editar" placement="bottom" bg="#aeaeae">
          <PencilSimple size={40} color="black" weight="duotone" />
        </Tooltip>
      </Styles.Icon>
      <Modal isCentered size={"xl"} isOpen={handleModal} onClose={CallModal}>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Editar conteúdo</ModalHeader>
          <ModalBody>
            <Styles.ModalBodyInputs>
              <div>
                <Styles.Text>Titulo:</Styles.Text>
                <InputData
                  width="30rem"
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                  value={data.title}
                />
              </div>

              <div>
                <Styles.Text>Resumo:</Styles.Text>
                <InputData
                  width="30rem"
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                  value={data.description}
                />
              </div>
              <div>
                <Styles.Text>Link Externo:</Styles.Text>
                <InputData
                  width="30rem"
                  onChange={(e) => setData({ ...data, video: e.target.value })}
                  value={data.video}
                />
              </div>
              <div>
                <Styles.Text>Modulos:</Styles.Text>
                <SelectModule
                  onChange={setData}
                  data={data}
                  module={data.module}
                />
              </div>
            </Styles.ModalBodyInputs>
          </ModalBody>
          {error === true && (
            <MessageError
              colorScheme="blackAlpha"
              AlertTitle="Erro na edição!"
              AlertDescription="Verifique se todos os campos estão preenchidos!"
            />
          )}
          <ModalFooter>
            <Styles.ModalFooterButton>
              {!!loading && <Spinner />}
              <Styles.Button mr={3} onClick={updateContent}>
                Salvar
              </Styles.Button>
              <Styles.Button mr={3} onClick={CallModal}>
                Cancelar
              </Styles.Button>
            </Styles.ModalFooterButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
