/* eslint-disable no-unused-vars */
import { DashboardNavBar } from "../../components/navbardashboard/index.js";
import { MainContainer } from "./style.js";
import * as Styles from "./style.js";
import { DashboardTable } from "./components/table/index.js";
import { InputData } from "../../components/input/index.js";
import Axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";
import useAuth from "../../hooks/useAuth.js";
import { ModalCreateContent } from "./components/modalCreate/index.js";
import { Spinner } from "@chakra-ui/react";

export const Content = () => {
  useAuth();
  const [content, setContent] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getContent();
  }, []);

  useEffect(() => {
    if (search !== "") {
      setContent([]);
      getContentSearch(search);
    } else {
      getContent();
    }
  }, [search]);

  const getContentSearch = async (search) => {
    setLoading(true);
    console.log(search);
    const response = await Axios.post(
      `${process.env.REACT_APP_URL_API}/contentSearch`,
      {
        title: search,
      }
    );

    if (response.data !== 0) {
      setContent(response.data);
      setLoading(false);
    } else {
      setContent([]);
    }
  };

  const getContent = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/contentDashboard`
    );

    if (response) {
      setLoading(false);
      setContent(response.data);
    } else {
      setLoading(true);
    }
  };

  return (
    <MainContainer>
      <DashboardNavBar />
      <Styles.TableContainer>
        <Styles.Text width="70%" margin-bottom="1rem">
          Controle
          <br />
          Conteúdo - FAQ
        </Styles.Text>
        <Styles.Input>
          <InputData
            width="30rem"
            placeholder="Pesquise aqui!"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <ModalCreateContent />
        </Styles.Input>

        <Styles.TableSpace>
          <Styles.Table>
            {!!loading && <Spinner size="xl" />}
            {content.map((response, key) => {
              const { title, score, id, idModule, video } = response;
              return (
                <DashboardTable
                  key={key}
                  title={title}
                  score={score}
                  id={id}
                  idModule={idModule}
                  video={video}
                  data={response}
                />
              );
            })}
          </Styles.Table>
          <Styles.TableArrows>
            <ArrowUp size={32} color="Black" weight="regular" />
            <ArrowDown size={32} color="Black" weight="regular" />
          </Styles.TableArrows>
        </Styles.TableSpace>
      </Styles.TableContainer>
    </MainContainer>
  );
};
