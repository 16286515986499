import ApexChartBar from "./components/barCharts/index.jsx";
import ApexChartRadar from "./components/radarCharts/index.jsx";
import { DashboardNavBar } from "../../components/navbardashboard/index.js";
import * as Styles from "./style.js";
import useAuth from "../../hooks/useAuth.js";
import { useState } from "react";
import Cookies from "js-cookie";

export const Dashboard = () => {
  const [name, setName] = useState(Cookies.get("name"));

  useAuth();

  return (
    <Styles.MainCoitainer>
      <DashboardNavBar />
      <Styles.Dashboard>
        <Styles.Header>
          <Styles.Text>
            Controle
            <br />
            Dashboard - FAQ
          </Styles.Text>
          <Styles.Text>Olá, {name}</Styles.Text>
        </Styles.Header>
        <Styles.Graph>
          <Styles.Board width="60%">
            <ApexChartBar />
          </Styles.Board>
          <Styles.Board width="35%">
            <ApexChartRadar />
          </Styles.Board>
        </Styles.Graph>
        <Styles.GraphTitle>
          <Styles.Text>Perguntas mais acessadas</Styles.Text>
          <Styles.Text>Módulos mais acessados</Styles.Text>
        </Styles.GraphTitle>
      </Styles.Dashboard>
    </Styles.MainCoitainer>
  );
};
