import * as Styles from "./style.js";
import { DebounceInput } from "react-debounce-input";

export const InputData = (props) => {
  return (
    <Styles.MainContainer width={props.width}>
      <DebounceInput
        type={props.type}
        minLength={1}
        debounceTimeout={400}
        placeholder={props.placeholder}
        onChange={(event) => props.onChange(event, props)}
        value={props.value}
      />
    </Styles.MainContainer>
  );
};
