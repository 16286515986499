import styled from "styled-components";

export const MainCoitainer = styled.div`
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: 3rem;
`;

export const Graph = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: 70%;
`;

export const Board = styled.div`
  ${(props) => props}
  display: flex;
  align-items: center;
  border-radius: 1rem;
  background: #6268a6;
`;

export const GraphTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 1rem;
`;

export const Text = styled.div``;
