import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;

  align-items: center;

  @media (max-width: 767px) {
    & {
      display: flex;
      max-width: 767px;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const PrimaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: auto;
  }
`;

export const Text = styled.div`
  text-align: left;
  font-size: 20px;
  ${(props) => props}
`;

export const ContainerAnimation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 90vh;

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }

  img {
    animation: float 2s ease-in-out infinite;
  }

  @media (max-width: 767px) {
    height: auto;
    margin-top: 5rem;
  }
`;

export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 50vw;
  gap: 20px;

  @media (max-width: 767px) {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Tags = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: auto;
  padding: 5px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .Tips {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 7px;
    width: auto;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 300px;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-width: 767px) {
    & {
      width: 100%;
      display: flex;
      align-items: center;
      max-height: 30vh;
    }
  }
`;

export const ContainerHelp = styled.div`
  width: 100%;
  height: 13rem;
  position: relative;
  background: #6268a6;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  @media (max-width: 767px) {
    & {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 8rem;
      align-items: center;

      .TitleHelp {
        text-align: center;
        margin-top: 1.7rem;
      }
    }
  }
`;

export const ContainerBlocks = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 200px;
  bottom: -65px;
  align-items: center;

  @media (max-width: 767px) {
    & {
      gap: 10px;
      bottom: -130px;
    }
  }
`;

export const ContainerQuestion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 20vh;
  /* margin-top: 0.5rem; */
`;

export const ContainerFrequent = styled.div`
  width: 80%;
  height: 40vh;
  display: flex;
  margin-top: 1.4rem;
  margin-bottom: 3rem;
  align-items: center;
  flex-direction: column;

  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;
