import { useEffect } from "react";
import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";

function ModalVideo(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (props.isOpen) {
      onOpen();
    }
  }, [onOpen, props.isOpen]);

  const closeModal = () => {
    onClose();
    props.setOpenModal(false);
  };

  return (
    <>
      <Modal isCentered size={"6xl"} isOpen={isOpen} onClose={closeModal} >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Vídeo: {props.videoTitle} </ModalHeader>
          <ModalBody
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <iframe
              title={props.videoTitle}
              width="1280"
              height="520"
              src={props.video}
            />
          </ModalBody>
          <ModalFooter>
            <Styles.Button mr={3} onClick={closeModal}>
              Fechar
            </Styles.Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalVideo;
