import styled from "styled-components";

export const MainContainer = styled.div`
  Input {
    ${(props) => props}
    border-radius: 5px;
    padding: 8px;
    outline: none;
    background-color: #d9d9d9;
    border: none;
    /* margin-bottom: 1rem; */
  }

  @media (max-width: 767px) {
    & {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
