const GlobalChakra = {
  styles: {
    global: {
      body: {
        margin: "0",
        padding: "0",
        fontFamily: "poppins, sans-serif",
        fontweight: 400,
      },
    },
  },
};

export default GlobalChakra;
