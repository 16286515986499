import styled from "styled-components";

export const Text = styled.div``;

export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #f1f1f1;
  display: flex;
  flex-direction: row;
`;

export const CostumerSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: 3rem;
`;

export const Options = styled.div`
  width: 90%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 30px;

  .Bloqueado {
    border: 1px solid red;
  }
`;

export const LicensesSubtitle = styled.div`
  width: 90%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .Cnpj {
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Name {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Status {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Date {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Version {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 3px;
    cursor: pointer;
  }

  .Config {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LicensesSpace = styled.div`
  width: 90%;
  height: auto;
  max-height: 640px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
  align-items: start;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 1rem;
  }
`;
