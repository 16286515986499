import * as Styles from "./style.js";
import { ModalEdit } from "../modalEdit/index.js";
import Axios from "axios";
import { ModalActionConfirm } from "../../../../components/modalConfirm/index.js";
import { useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { TrashSimple } from "phosphor-react";

export const DashboardTable = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const data = props.data;
    await Axios.post(`${process.env.REACT_APP_URL_API}/deleteContent`, {
      id: data.id,
    });
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <Styles.Table>
      <Styles.Card>
        <Styles.Info>
          <Styles.Text>
            <strong>Titulo: </strong> {props.title}
          </Styles.Text>
          <Styles.Text>
            <strong>Score:</strong> {props.score}
          </Styles.Text>
        </Styles.Info>
        <Styles.Options>
          {!!loading && <Spinner />}
          <Styles.Icon>
            <ModalEdit data={props.data} onReload />
          </Styles.Icon>
          <Styles.Icon>
            <ModalActionConfirm
              onConfirm={handleDelete}
              icon={<TrashSimple size={40} color="black" weight="duotone" />}
              label="Tem certeza que deseja deletar este conteúdo?"
            />
          </Styles.Icon>
        </Styles.Options>
      </Styles.Card>
    </Styles.Table>
  );
};
