import * as Styles from "./style.js";
import logo from "../../public/logo.svg";
import { Button } from "../../components/button/index.js";
import Axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { MessageError } from "../../components/messageError/index.js";
import { Spinner } from "@chakra-ui/react";

export const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const emailcookie = Cookies.get("email") !== undefined;

  useEffect(() => {
    if (emailcookie) {
      navigate("/dashboard");
    }
  }, [emailcookie, navigate]);

  const handleClickLogin = async () => {
    setError(false);
    setLoad(true);
    // navigate("/dashboard");
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_URL_API}/verifylogin`,
        {
          email: email,
          password: password,
        }
      );

      if (response.data.success) {
        const { name } = response.data.data;
        Cookies.set("email", email, { expires: 30 });
        Cookies.set("name", name, { expires: 30 });
        navigate("/dashboard");
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setError(true);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <Styles.Main>
        <Styles.Login>
          <Styles.LoginHeader>
            <img src={logo} alt="logo" />
          </Styles.LoginHeader>
          <Styles.LoginBody>
            <Styles.LoginData>
              <Styles.LoginDataFilds>
                <Styles.Text color="white" marginBottom="1rem" fontSize="18px">
                  Entre na sua conta!
                </Styles.Text>
                <div className="Inputs">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Login"
                  />
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Senha"
                  />
                </div>
                {load && (
                  <div className="Loader">
                    <Spinner size="md" color="white" speed="0.65s" />
                  </div>
                )}
                <Button title="Logar" onClick={handleClickLogin}></Button>
              </Styles.LoginDataFilds>
            </Styles.LoginData>
          </Styles.LoginBody>
          {error && (
            <MessageError
              width="25rem"
              colorScheme="telegram"
              AlertTitle="Erro no login!"
              AlertDescription="Usuário ou senha errados!"
            />
          )}
        </Styles.Login>
      </Styles.Main>
    </>
  );
};
