import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import { Envelope } from "phosphor-react";
import Axios from "axios";
import { MessageError } from "../../../../components/messageError/index.js";
import { InputDataTextArea } from "../../../../components/inputTextArea/index.js";

export const ModalMensagePrivate = (props) => {
  const [handleModal, setHandleModal] = useState(false);
  const [mensage, setMensage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateContent = async () => {
    //Colocar efeito de loading junto da lógica de inclusão da mensagem
  };

  const CallModal = () => {
    if (handleModal === false) {
      setHandleModal(true);
    } else {
      setHandleModal(false);
    }
  };

  return (
    <>
      <Styles.Icon onClick={CallModal}>
        <Tooltip label="Recado geral" placement="bottom" bg="#aeaeae">
          <Envelope weight="duotone" size={40} color="#aeaeae" />
        </Tooltip>
        Mensagem privada
      </Styles.Icon>
      <Modal isCentered size={"xl"} isOpen={handleModal} onClose={CallModal}>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Enviar mensagem privada</ModalHeader>
          <ModalBody>
            <Styles.ModalBodyInputs>
              <Styles.Text>Mensagem:</Styles.Text>
              <InputDataTextArea
                placeholder="Escreva a mensagem aqui."
                onChange={(e) => setMensage(e.target.value)}
                value={mensage}
              />
            </Styles.ModalBodyInputs>
          </ModalBody>
          {error === true && (
            <MessageError
              colorScheme="blackAlpha"
              AlertTitle="Erro na edição!"
              AlertDescription="Verifique se todos os campos estão preenchidos!"
            />
          )}
          <ModalFooter>
            <Styles.ModalFooterButton>
              {!!loading && <Spinner />}
              <Styles.Button mr={3} onClick={updateContent}>
                Enviar
              </Styles.Button>
              <Styles.Button mr={3} onClick={CallModal}>
                Cancelar
              </Styles.Button>
            </Styles.ModalFooterButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
