import styled from "styled-components";

export const Text = styled.div``;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 200px;
  background: #d9d9d9;
  border-radius: 10px;
  padding: 10px;
`;

export const UserHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30%;
`;

export const UserBody = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 5px;

  .Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    width: 45px;
    height: 45px;
    transition: 0.2s;

    &:hover {
      background: #c3c3c3;
      border-radius: 10px;
    }
  }
`;
