import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

import { RoutesAuth } from "./routes.js";
import  GlobalChakra  from "./styles/global.js";

export function App() {
  const theme = extendTheme(GlobalChakra);

  return (
    <>
      <ChakraProvider theme={theme}>
        <RoutesAuth />
      </ChakraProvider>
    </>
  );
}
