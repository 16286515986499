import styled from "styled-components";

export const NavBar = styled.div`
  background: #6268a6;
  width: 210px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const ContentNavBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .home {
    margin-bottom: 10px;
  }

  .options {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }

  .singOut {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 15px;
    height: 100%;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: start;
  transition: 0.5s;
  width: 170px;
  height: 60px;
  color: white;
  gap: 10px;

  .Icon {
  }

  .Title {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: #4a4f7d;
    border-radius: 0.7rem;
  }
`;
