import styled from "styled-components";

export const Text = styled.div``;

export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #f1f1f1;
  display: flex;
  flex-direction: row;
`;

export const UserSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: 3rem;
`;

export const UserSpaceCards = styled.div`
  width: 90%;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: start;
  align-items: start;

  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const CreateUserCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100px;
  /* background: #81ad21; */
  border-radius: 10px;
`;
