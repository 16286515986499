import { YoutubeLogo } from "phosphor-react";
import * as Styles from "./style.js";
import { useState } from "react";
import ModalVideo from "../../../home/components/modalVideo/index.js";

export const Files = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [paramsModal, setParamsModal] = useState(null);

  const Playvideo = () => {
    const { video, title } = props;

    setParamsModal({ video, title });

    setOpenModal(true);
  };

  return (
    <Styles.MainContainer>
      <Styles.Item onClick={Playvideo}>
        {openModal && (
          <ModalVideo
            isOpen={openModal}
            video={paramsModal.video}
            videoTitle={paramsModal.title}
          />
        )}
        <Styles.Icons>
          <YoutubeLogo size={38} color="#81ad21" weight="duotone" />
        </Styles.Icons>
        <Styles.Content>{props.title}</Styles.Content>
      </Styles.Item>
    </Styles.MainContainer>
  );
};
