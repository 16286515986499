import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { InputData } from "../../../../components/input/index.js";
import { useState } from "react";
import { PencilSimple } from "phosphor-react";
import Axios from "axios";
import { MessageError } from "../../../../components/messageError/index.js";
import bcrypt from "bcryptjs-react";
import { useEffect } from "react";

export const ModalEditUser = (props) => {
  const [handleModal, setHandleModal] = useState(false);
  const [data, setData] = useState(props.data);
  const [error, setError] = useState(false);
  const [icon, setIcon] = useState("ph-eye-slash");
  const [typeFild, setTypeFild] = useState("password");
  const [password] = useState(props.data.password);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const getPassword = () => {
    if (icon !== "ph-eye") {
      setIcon("ph-eye");
      setTypeFild("text");
    } else {
      setIcon("ph-eye-slash");
      setTypeFild("password");
    }
  };

  const updateContent = async () => {
    if (data.name !== "" && data.email !== "") {
      setLoading(true);
      const hashPassword =
        data.password === "" || data.password !== props.data.password
          ? await bcrypt.hash(password, 8)
          : props.data.password;
      await Axios.post(`${process.env.REACT_APP_URL_API}/updateUser`, {
        id: data.id,
        name: data.name,
        email: data.email,
        password: hashPassword,
      });
      setHandleModal(false);
      setError(false);
      setLoading(false);
      setData([]);
      console.log(hashPassword)
      setTimeout(() => {
        // window.location.reload();
      }, 500);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const CallModal = () => {
    if (handleModal === false) {
      setHandleModal(true);
    } else {
      setHandleModal(false);
      setData(props.data);
    }
  };

  return (
    <>
      <Styles.Icon onClick={CallModal}>
        <Tooltip label="Editar" placement="bottom" bg="#aeaeae">
          <PencilSimple weight="duotone" size={40} color="black" />
        </Tooltip>
      </Styles.Icon>
      <Modal isCentered size={"xl"} isOpen={handleModal} onClose={CallModal}>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Editar usuário</ModalHeader>
          <ModalBody>
            <Styles.ModalBodyInputs>
              <Styles.Text>Nome:</Styles.Text>
              <InputData
                width="30rem"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name}
              />
              <Styles.Text>Email:</Styles.Text>
              <InputData
                width="30rem"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                value={data.email}
              />

              <Styles.Text>Nova senha:</Styles.Text>
              <div className="password">
                <InputData
                  width="27rem"
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                  type={typeFild}
                />

                <div className="Icon" onClick={getPassword}>
                  <i className={icon}></i>
                </div>
              </div>
            </Styles.ModalBodyInputs>
          </ModalBody>
          {error === true && (
            <MessageError
              colorScheme="blackAlpha"
              AlertTitle="Erro na edição!"
              AlertDescription="Verifique se todos os campos estão preenchidos!"
            />
          )}
          <ModalFooter>
            <Styles.ModalFooterButton>
              {!!loading && <Spinner />}
              <Styles.Button mr={3} onClick={updateContent}>
                Salvar
              </Styles.Button>
              <Styles.Button mr={3} onClick={CallModal}>
                Cancelar
              </Styles.Button>
            </Styles.ModalFooterButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
