/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { DashboardNavBar } from "../../components/navbardashboard/index.js";
import * as Styles from "./style.js";
import Cookies from "js-cookie";
import { UserCard } from "./components/userCard/index.js";
import useAuth from "../../hooks/useAuth.js";
import Axios from "axios";
import { ModalCreateUser } from "./components/modalCreate/index.js";
import { Spinner } from "@chakra-ui/react";

export const Users = () => {
  useAuth();
  const [name, setName] = useState(Cookies.get("name"));
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const response = await Axios.get(`${process.env.REACT_APP_URL_API}/users`);

    if (response) {
      setUsers(response.data);
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  return (
    <Styles.MainContainer>
      <DashboardNavBar />
      <Styles.UserSpace>
        <Styles.Header>
          <Styles.Text>
            Controle
            <br />
            Usuários
          </Styles.Text>
          <Styles.Text>Olá, {name}</Styles.Text>
        </Styles.Header>
        <Styles.CreateUserCard>
          <ModalCreateUser />
        </Styles.CreateUserCard>
        <Styles.UserSpaceCards>
          {users.map((response, key) => {
            const { id, name, email, password, type } = response;
            return (
              <UserCard
                data={response}
                key={key}
                name={name}
                email={email}
                password={password}
                type={type}
                id={id}
              />
            );
          })}
        </Styles.UserSpaceCards>
        {!!loading && <Spinner size="xl" />}
      </Styles.UserSpace>
    </Styles.MainContainer>
  );
};
