import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";

export const ModalActionConfirm = (props) => {
  const actionConfirm = props.onConfirm;
  const [handleModal, setHandleModal] = useState(false);

  const CallModal = () => {
    if (handleModal === false) {
      setHandleModal(true);
    } else {
      setHandleModal(false);
    }
  };

  return (
    <>
      <Styles.Icon onClick={CallModal}>
        <Tooltip label="Excluir" placement="bottom" bg="#aeaeae">
          {/* <TrashSimple size={30} color="black" /> */}
          {props.icon}
        </Tooltip>
      </Styles.Icon>
      <Modal isCentered size={"lg"} isOpen={handleModal} onClose={CallModal}>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Aviso!</ModalHeader>
          <ModalBody>
            <Styles.Text>{props.label}</Styles.Text>
            <Styles.ModalBodyInputs>
              <Styles.ModalFooterButton>
                <Styles.Button
                  mr={3}
                  onClick={() => {
                    actionConfirm();
                    setHandleModal(false);
                  }}
                >
                  Sim
                </Styles.Button>
                <Styles.Button mr={3} onClick={CallModal}>
                  Não
                </Styles.Button>
              </Styles.ModalFooterButton>
            </Styles.ModalBodyInputs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
