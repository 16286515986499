import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { Info } from "phosphor-react";

export const ModalAlert = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Styles.Button onClick={onOpen}>
        Como usar? <i className="ph-fill ph-info"></i>
      </Styles.Button>
      <Modal isCentered size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Como usar o FAQ!</ModalHeader>
          <ModalBody>
            Bem-vindo ao nosso FAQ! Aqui, você pode encontrar respostas para
            suas dúvidas assistindo vídeos curtos de apenas um minuto. Para
            encontrar o material que deseja, basta ir à guia de pesquisa ao lado
            direito e digitar palavras-chave relevantes ou usar as tags como
            "incluir produto" ou "equipamentos".
            <br />
            <br /> As perguntas são classificadas com base na popularidade,
            então as mais acessadas aparecerão primeiro na lista. Além disso,
            você pode encontrar links úteis na parte inferior do site, incluindo
            um link especial para os vídeos. Lá, você pode ter um acesso mais
            abrangente aos vídeos do FAQ e fazer pesquisas com facilidade.
            <br />
            <br />
            Esperamos que nosso FAQ possa ajudá-lo a encontrar todas as
            respostas que você precisa!
          </ModalBody>

          <ModalFooter>
            <Styles.Button mr={3} onClick={onClose}>
              Fechar
            </Styles.Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
