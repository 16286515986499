import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

export const MessageError = (props) => {
  return (
    <Alert
      colorScheme={props.colorScheme}
      status="error"
      width={props.width}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <AlertIcon />
      <AlertTitle>{props.AlertTitle}</AlertTitle>
      <AlertDescription>{props.AlertDescription}</AlertDescription>
    </Alert>
  );
};
