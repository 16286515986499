import styled from "styled-components";
import bglogin from "../../public/bglogin.png";

export const Main = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${bglogin});
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: content-box;
`;

export const Login = styled.div`
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2rem;
  background-image: url(${bglogin});
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-shadow: 0.5px 0.5px 20px #0c1c38;
`;

export const LoginHeader = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
`;

export const LoginData = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginDataFilds = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 80%;
  background-color: #49568d50;
  border-radius: 1rem;

  .Loader {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .Inputs {
    height: 4.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: 1rem;
    }
  }

  input {
    width: 15rem;
    height: 2rem;
    border-radius: 0.2rem;
    padding: 0.5rem;
  }
`;

export const Text = styled.div`
  ${(props) => props}
`;
