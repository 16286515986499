import * as Styles from "./style.js";
import Axios from "axios";
import { useEffect, useState } from "react";

import Chart from "react-apexcharts";

const ApexChartBar = () => {
  const [contentScore, setContentScore] = useState([]);

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    getContentScore();
  }, []);

  const getContentScore = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/contentScore`
    ).catch((err) => console.log(err));

    if (response) {
      setContentScore(response.data);

      let chartData = [];
      let chartCategories = [];

      response.data.forEach((data) => {
        chartData.push(data.score);
        chartCategories.push(data.title);
      });

      const categories = {
        chart: {
          id: "bar",
          fontFamily: "Poppins",
          foreColor: "#ffffff",
        },
        fill: {
          colors: "#FD6D4B",
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        xaxis: {
          categories: chartCategories,
        },
      };

      const data = {
        name: "Pontos",
        data: chartData,
      };

      setOptions(categories);
      setSeries([data]);
    }
  };

  return (
    <Styles.Chart>
      <Chart options={options} series={series} type="bar" height={350} />
    </Styles.Chart>
  );
};

export default ApexChartBar;
