/* eslint-disable no-unused-vars */
import * as Styles from "./style.js";
import { MenuCostumers } from "../menu/index.js";
import { useState } from "react";
import axios from "axios";

export const Costumers = (props) => {
  const [status, setStatus] = useState(props.status);
  const [id, setId] = useState(props.id);

  const handleUpdateLicense = () => {
    if (status === "Liberado") {
      // Cria uma nova instância do objeto Date para obter a data atual
      const currentDate = new Date();
      // Soma 5 dias à data atual
      currentDate.setDate(currentDate.getDate() + 5);
      // Extrai as informações de data (dia, mês e ano) da instância do Date
      const day = currentDate.getDate(); // Pega o dia atualizado
      const month = currentDate.getMonth() + 1; // Os meses são indexados de 0 a 11, por isso somamos 1.
      const year = currentDate.getFullYear();
      // Formata a data no formato desejado (por exemplo, DD/MM/AAAA)
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  
      axios.post(`${process.env.REACT_APP_URL_API}/update_company_status`, {
        id: id,
        ativo: status === "Bloqueado" ? 1 : 0,
        data_validade: formattedDate,
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      const formattedDate = "2050-01-01";
      axios.post(`${process.env.REACT_APP_URL_API}/update_company_status`, {
        id: id,
        ativo: status === "Liberado" ? 0 : 1,
        data_validade: formattedDate,
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  return (
    <Styles.MainContainer>
      <Styles.Name>{props.name}</Styles.Name>
      <Styles.Cnpj>{props.cnpj}</Styles.Cnpj>
      <Styles.Status
        background={props.status === "Liberado" ? "#81ad21" : "#d03830"}
      >
        <div className="StatusColor">{props.status}</div>
      </Styles.Status>
      <Styles.Date>{props.date}</Styles.Date>
      <Styles.Version>V: {props.version}</Styles.Version>
      <Styles.Config>
        <MenuCostumers onClick={handleUpdateLicense} />
      </Styles.Config>
    </Styles.MainContainer>
  );
};
