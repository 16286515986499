import styled from "styled-components";

export const MainContainer = styled.div``;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #484e8d;
  border: none;
  border-radius: 0.25rem;
  padding: 10px;
  color: white;
  width: 160px;
  margin-top: 1rem;
  transition: 0.2s;
  animation: linear;

  &:hover {
    background: #91ad11;
  }

  i {
    font-size: 28px;
  }
`;
