import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: none;
  width: 100%;
  height: 8%;
  font-size: medium;
  transition: 0.4s;

  &:hover {
    background: #d2d2d2;
  }

  .icon {
    margin-right: 1rem;
  }

  @media (max-width: 767px) {
    & {
      width: 100vw;
      height: 5rem;
      display: flex;
      flex-direction: column;
    }
  }

`;
