import * as Styles from "./style.js";

export const Blocks = (props) => {
  const { onClick, icon, title } = props;
  return (
      <Styles.Blocks variant="solid" onClick={onClick}>
        <i className={icon} />
        <Styles.Text>{title}</Styles.Text>
      </Styles.Blocks>
    
  );
};
