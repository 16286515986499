import styled from "styled-components";

export const MainContainer = styled.div`
  background: #f1f1f1;
  width: 100vw;
  height: 90vh;

  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    & {
      width: 100vw;
      height: 140vh;
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

export const MainCategories = styled.div`
  width: 20vw;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5rem;

  @media (max-width: 767px) {
    & {
      width: 100vw;
      height: 40rem;
      display: flex;
      flex-direction: column;

      overflow-y: auto;
      position: relative;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
`;

export const MainDocuments = styled.div`
  width: 40vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #f1f1f1;

  @media (max-width: 767px) {
    & {
      width: 100vw;
      height: 30rem;
      display: flex;
      flex-direction: column;

      overflow-y: auto;
      position: relative;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
`;

export const DocumentsTitle = styled.div`
  width: 100%;
  height: 17vh;

  @media (max-width: 767px) {
    & {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const DocumentsFiles = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 15px;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-width: 767px) {
    & {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const MainSearch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40%;
  width: 40%;

  @media (max-width: 767px) {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 30%;
    }
  }
`;

export const SearchReload = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1rem;

  @media (max-width: 767px) {
    & {
      display: flex;
      width: 70%;
      flex-direction: column;
      justify-content: space-around;
    }
  }
`;

export const SearchReloadFilter = styled.div`
  display: flex;
  width: 8rem;
  flex-direction: row;
  align-items: center;

  .tag {
    background: #446632;
    padding: 0.2rem;
    border-radius: 0.25rem;
    color: white;
  }
`;

export const Button = styled.button`
  border: 1px solid red;
  border: none;
  max-width: 3rem;
  border-radius: 0.25rem;
  background: #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  transition: 0.4s;

  &:hover {
    scale: 1.2;
  }
`;

export const BackButton = styled.button`
  display: flex;
  border: none;
  background: #f1f1f1;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
  transition: 0.4s;

  &:hover {
    transform: translateX(-1rem);
  }
`;

export const Text = styled.div`
  ${(props) => props}
  font-size: 20px;
`;
