import styled from "styled-components";

export const Main = styled.button`
  width: auto;
  max-height: 35px;
  padding: 5px;
  background: #d9d9d9;
  border: 2px solid #a5a5a5;
  border-radius: 10px;
  transition: 0.1s;

  &:hover {
    scale: 1.1;
    z-index: 1;
    background: #dbe4c8;
    border: 2px solid #81ad21;
  }

  @media (max-width: 767px) {
    transition: 0.1s;
    height: 7rem;
    width: auto;

    &:hover {
      background: #d9d9d9;
      border: 2px solid #a5a5a5;
      scale: 1;
    }
  }
`;

export const Text = styled.div`
  color: black;
  font-size: 14px;
`;
