import * as Styles from "./style.js";
import { Navbar } from "../../components/navBar/index.js";
import { Categories } from "./components/categories/index.js";
import { Files } from "./components/files/index.js";
import { InputData } from "../../components/input/index.js";
import { useEffect, useState } from "react";
import { ArrowLeft, TrashSimple } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

export const Docs = () => {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [type, setTypes] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (search !== "") {
      //Faz a pesquisa pelo texto
      getContentSearch(search);
    } else {
      //desabilita a pesquisa e mostra os contens
      getContent();
      getTypes();
    }
  }, [search]);

  useEffect(() => {
    if (searchType !== "") {
      getContentSearchType(searchType);
    } else {
      getContent();
      getTypes();
    }
  }, [searchType]);

  //busca os tipos
  const getTypes = async () => {
    const response = await Axios.get(`${process.env.REACT_APP_URL_API}/types`);

    if (response) {
      setTypes(response.data);
    }
  };

  //busca os contents
  const getContent = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/content`
    );

    if (response) {
      setContent(response.data);
    }
  };

  //passa a pesquisa para o sql
  const getContentSearch = async (search) => {
    const response = await Axios.post(
      `${process.env.REACT_APP_URL_API}/contentSearch`,
      {
        title: search,
      }
    );

    if (response) {
      setContent(response.data);
    }
  };

  //passa o tipo selecionado para o sql de busca
  const getContentSearchType = async (type) => {
    const response = await Axios.post(
      `${process.env.REACT_APP_URL_API}/contentSearchType`,
      {
        module: type,
      }
    );

    if (response) {
      setContent(response.data);
    }
  };

  //seta o filtro para vazio
  const reloadFilter = () => {
    setSearch("");
    setSearchType("");
  };

  return (
    <>
      <Navbar />
      <Styles.MainContainer>
        <Styles.MainCategories>
          <Styles.Text margin-top="3rem" margin-bottom="3rem">
            <strong>Categorias</strong>
          </Styles.Text>
          {type.map((response, key) => {
            const { id, module } = response;
            return (
              <Categories
                key={key}
                title={module}
                onClick={() => {
                  setSearchType(id);
                }}
              />
            );
          })}
        </Styles.MainCategories>
        <Styles.MainDocuments>
          <Styles.DocumentsTitle>
            <Styles.Text margin-top="3rem" margin-bottom="3rem">
              <strong>Vídeos</strong>
            </Styles.Text>
          </Styles.DocumentsTitle>
          <Styles.DocumentsFiles>
            {content.map((response, key) => {
              const { title, type, video } = response;
              return (
                <Files key={key} title={title} video={video} type={type} />
              );
            })}
          </Styles.DocumentsFiles>
        </Styles.MainDocuments>
        <Styles.MainSearch>
          <Styles.SearchReload>
            <Styles.SearchReloadFilter>
              <Styles.Button onClick={reloadFilter}>
                <TrashSimple
                  className="icon"
                  size={26}
                  color="black"
                  weight="bold"
                />
              </Styles.Button>
            </Styles.SearchReloadFilter>

            <InputData
              width="30rem"
              placeholder="Pesquise Aqui!"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />

            <Styles.BackButton
              onClick={() => {
                navigate("/");
              }}
            >
              <ArrowLeft
                className="icon"
                size={28}
                color="black"
                weight="bold"
              />
              Voltar para FAQ
            </Styles.BackButton>
            <Styles.Text>
              <Styles.BackButton
                onClick={() => {
                  window.open(
                    "https://servidorseguro.mysuite.com.br/client/login.php?sl=pts"
                  );
                }}
              >
                Ir para Suporte
              </Styles.BackButton>
            </Styles.Text>
            <Styles.Text>
              <Styles.BackButton
                onClick={() => {
                  window.open("https://softilux.com.br/ead/");
                }}
              >
                Ir para EaD
              </Styles.BackButton>
            </Styles.Text>
          </Styles.SearchReload>
        </Styles.MainSearch>
      </Styles.MainContainer>
    </>
  );
};
