import * as Styles from "./style.js";
import { useState } from "react";
import { Navbar } from "../../components/navBar/index.js";
import Bitlux from "../../public/bitlux.svg";
import { InputData } from "../../components/input/index.js";
import { AccordionAnswer } from "./components/accordion/index.js";
import { Blocks } from "./components/blocks/index.js";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Axios from "axios";
import { ModalAlert } from "./components/modalAlert/index.js";
import { Tags } from "./components/tags/index.js";

export const Home = () => {
  const [search, setSearch] = useState("");
  const [content, setContent] = useState([]);
  const [contentScore, setContentScore] = useState([]);
  const [error, setError] = useState(false);
  const tips = [
    "Produto",
    "Estoque",
    "Despesas",
    "Receitas",
    "Pedido de Venda",
    "NF-e",
    "NFS-e",
    "MDF-e",
    "Inclusão",
    "Equipamento",
    "Locação",
    "Contrato",
    "Cancelar",
    "Leitura",
    "Ordem de Serviço",
    "App",
  ];

  useEffect(() => {
    if (search !== "") {
      getContentSearch(search);
    } else {
      getContent();
      getContentScore();
    }
  }, [search]);

  let navigate = useNavigate();

  const getContent = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/content`
    );

    if (response) {
      setContent(response.data);
      setError("");
    }
  };

  const getContentSearch = async (search) => {
    const response = await Axios.post(
      `${process.env.REACT_APP_URL_API}/contentSearch`,
      {
        title: search,
      }
    );

    if (response.data.length !== 0) {
      setContent(response.data);
      setError(false);
    } else {
      setContent([]);
      setError(true);
    }
  };

  const getContentScore = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/contentScore`
    ).catch((err) => console.log(err));

    if (response) {
      setContentScore(response.data);
    }
  };

  const scorePoints = (id) => {
    Axios.post(`${process.env.REACT_APP_URL_API}/updateScore`, {
      id: id,
    });
  };

  const newTips = (title) => {
    setSearch(title);
  };

  return (
    <>
      <Navbar />
      <Styles.MainContainer>
        <Styles.PrimaryContainer>
          <Styles.ContainerAnimation>
            <img src={Bitlux} alt="Bitlux" />
            <Styles.Text font-size="18px" textAlign="center">
              <p>
                Ele pode te ajudar
                <strong> conheça o Bitlux!</strong>
                <br />
                Olá, vai ser um prazer ajudar você.
              </p>
            </Styles.Text>
            <ModalAlert />
          </Styles.ContainerAnimation>
          <Styles.ContainerData>
            <Styles.Text font-size="24px" margin-bottom="1rem">
              <p>
                <strong>Tire sua dúvida com Bitlux</strong>
              </p>
            </Styles.Text>
            <InputData
              width="430px"
              placeholder="Pesquise Aqui!"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <Styles.ContainerInfo>
              {content.map((response, key) => {
                const { title, description, id, video } = response;
                return (
                  <AccordionAnswer
                    key={key}
                    title={title}
                    text={description}
                    id={id}
                    video={video}
                    onClick={scorePoints}
                  />
                );
              })}
              {error && (
                <span>Ops, parece que não temos nenhum resultado. 😢</span>
              )}
            </Styles.ContainerInfo>
            <Styles.Tags>
              <div className="Tips">
                {tips.map((response, key) => {
                  return (
                    <Tags
                      title={response}
                      key={key}
                      onClick={() => {
                        newTips(response);
                      }}
                    />
                  );
                })}
              </div>
            </Styles.Tags>
          </Styles.ContainerData>
        </Styles.PrimaryContainer>
        <Styles.ContainerHelp>
          <Styles.Text
            className="TitleHelp"
            margin-top="3rem"
            font-size="28px"
            color="white"
          >
            <strong>Opa, precisando de um atalho?</strong>
          </Styles.Text>
          <Styles.ContainerBlocks>
            <Blocks
              title="Ead"
              icon="ph-light ph-book"
              onClick={() => {
                window.open("https://softilux.com.br/ead/");
              }}
            />
            <Blocks
              title="Videos"
              icon="ph-thin ph-youtube-logo"
              onClick={() => {
                navigate("/documents");
              }}
            />
            <Blocks
              title="suporte"
              icon="ph-light ph-headset"
              onClick={() => {
                window.open(
                  "https://servidorseguro.mysuite.com.br/client/login.php?sl=pts"
                );
              }}
            />
          </Styles.ContainerBlocks>
        </Styles.ContainerHelp>
        <Styles.ContainerQuestion>
          <Styles.Text font-size="24px">
            <strong>Perguntas mais frequentes</strong>
          </Styles.Text>
        </Styles.ContainerQuestion>
        <Styles.ContainerFrequent>
          {contentScore.map((response, key) => {
            const { title, description, id, video } = response;
            return (
              <AccordionAnswer
                key={key}
                title={title}
                video={video}
                text={description}
                onClick={scorePoints}
                id={id}
              />
            );
          })}
        </Styles.ContainerFrequent>
      </Styles.MainContainer>
    </>
  );
};
