import styled from "styled-components";

export const MainContainer = styled.div``;

export const Button = styled.button`
  background-color: #484e8d;
  border: none;
  border-radius: 0.25rem;
  padding: 10px;
  color: white;
  width: 100px;
  margin-top: 1rem;
  transition: 0.2s;
  animation: linear;
  
  &:hover {
    background: #d03830;
    color: black;
  }
`;
