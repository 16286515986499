/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { DashboardNavBar } from "../../components/navbardashboard/index.js";
import useAuth from "../../hooks/useAuth.js";
import * as Styles from "./style.js";
import Cookies from "js-cookie";
import { Costumers } from "./components/Costumers/index.js";
import { InputData } from "../../components/input/index.js";
import { Checkbox, Spinner, Tooltip } from "@chakra-ui/react";
import Axios from "axios";
import { ModalMensage } from "./components/modalMensage/index.js";
import { CaretDown, CaretUp } from "phosphor-react";
import { ModalCreateCompany } from "./components/modalCreateCompany/index.js";

export const Licenses = () => {
  useAuth();
  const [name, setName] = useState(Cookies.get("name"));
  const [company, setCompany] = useState([]);
  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [desc, setDesc] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (search !== "") {
      getCompanySearch(search);
    } else {
      getCompany();
    }
  }, [search]);

  useEffect(() => {
    if (isChecked === true) {
      getBlocklicenses();
    } else {
      setSearch("");
      getCompany();
    }
  }, [isChecked]);

  const handleChangeOrderVersion = () => {
    if (desc === true) {
      setDesc(false);
      getCompanyAsc();
    } else {
      setDesc(true);
      getCompanyDesc();
    }
  };

  const getCompany = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/pws_company`
    );

    if (response) {
      setCompany(response.data);
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const getBlocklicenses = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/pws_company_search_block`
    );

    if (response) {
      setCompany(response.data);
    }
  };

  const getCompanySearch = async (search) => {
    const response = await Axios.post(
      `${process.env.REACT_APP_URL_API}/pws_company_search`,
      {
        empresa: search,
      }
    );

    if (response.data !== 0) {
      setCompany(response.data);
    } else {
      setCompany([]);
    }
  };

  const getCompanyDesc = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/pws_company_desc`
    );

    if (response) {
      setCompany(response.data);
    }
  };

  const getCompanyAsc = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/pws_company_asc`
    );

    if (response) {
      setCompany(response.data);
    }
  };

  return (
    <Styles.MainContainer>
      <DashboardNavBar />
      <Styles.CostumerSpace>
        <Styles.Header>
          <Styles.Text>
            Controle
            <br />
            Licenças
          </Styles.Text>
          <Styles.Text>Olá, {name}</Styles.Text>
        </Styles.Header>
        <Styles.Options>
          <Checkbox
            defaultChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            value={isChecked}
          >
            <Tooltip
              label="Somente clientes bloqueados"
              placement="bottom"
              bg="#aeaeae"
            >
              Bloqueados
            </Tooltip>
          </Checkbox>

          <InputData
            width="250px"
            placeholder="Pesquisar empresa"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <ModalMensage />
          <ModalCreateCompany />
        </Styles.Options>
        <Styles.LicensesSubtitle>
          <div className="Name">Nome</div>
          <div className="Cnpj">Cnpj</div>
          <div className="Status">Status</div>
          <div className="Date">Data Validade</div>
          <div className="Version" onClick={handleChangeOrderVersion}>
            Versão
            {desc === true ? (
              <CaretUp size={14} weight="fill" />
            ) : (
              <CaretDown size={14} weight="fill" />
            )}
          </div>
          <div className="Config">Opções</div>
        </Styles.LicensesSubtitle>
        {!!loading && <Spinner size="xl" />}
        <Styles.LicensesSpace>
          {company.map((response, key) => {
            const { id, empresa, cnpj, ativo, data_validade, versao_ilux } =
              response;

            function formatData(data_validade) {
              const date = new Date(data_validade);

              const datavalidade = date.toLocaleDateString();

              const formatedDate = datavalidade;

              return datavalidade === "31/12/1969" ? "" : formatedDate;
            }

            return (
              <Costumers
                id={id}
                key={key}
                cnpj={cnpj !== null ? cnpj : "Não informado"}
                name={empresa}
                status={ativo === 1 ? "Liberado" : "Bloqueado"}
                date={formatData(data_validade)}
                version={versao_ilux}
              />
            );
          })}
        </Styles.LicensesSpace>
      </Styles.CostumerSpace>
    </Styles.MainContainer>
  );
};
