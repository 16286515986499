import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Tooltip,
  FormControl,
  Spinner,
} from "@chakra-ui/react";
import { InputData } from "../../../../components/input/index.js";
import { useState } from "react";
import { PlusCircle } from "phosphor-react";
import Axios from "axios";
import { MessageError } from "../../../../components/messageError/index.js";
import bcrypt from "bcryptjs-react";

export const ModalCreateUser = () => {
  const [handleModal, setHandleModal] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [icon, setIcon] = useState("ph-eye-slash");
  const [typeFild, setTypeFild] = useState("password");
  const [loading, setLoading] = useState(false);

  const getPassword = () => {
    if (icon !== "ph-eye") {
      setIcon("ph-eye");
      setTypeFild("text");
    } else {
      setIcon("ph-eye-slash");
      setTypeFild("password");
    }
  };

  const CreateUser = async () => {
    if (
      data.name !== undefined &&
      data.email !== undefined &&
      data.password !== undefined
    ) {
      setLoading(true);
      const hashPassword = await bcrypt.hash(data.password, 8);
      await Axios.post(`${process.env.REACT_APP_URL_API}/createUser`, {
        name: data.name,
        email: data.email,
        password: hashPassword,
      });
      setHandleModal(false);
      setError(false);
      setLoading(false);
      setData([]);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const CallModal = () => {
    if (handleModal === false) {
      setHandleModal(true);
      setError(false);
    } else {
      setData([]);
      setHandleModal(false);
      setError(false);
    }
  };

  return (
    <>
      <Styles.Icon onClick={CallModal}>
        <Tooltip label="Incluir usuário" placement="bottom" bg="#91ad11">
          <PlusCircle
            className="plus"
            size={70}
            color="#91ad11"
            weight="duotone"
            onClick={CallModal}
          />
        </Tooltip>
      </Styles.Icon>
      <Modal isCentered size={"xl"} isOpen={handleModal} onClose={CallModal}>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Criar usuário</ModalHeader>
          <ModalBody>
            <FormControl>
              <Styles.ModalBodyInputs>
                <Styles.Text>Nome:</Styles.Text>
                <InputData
                  width="30rem"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  value={data.name}
                />
                <Styles.Text>Email:</Styles.Text>
                <InputData
                  width="30rem"
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  value={data.email}
                />
                <Styles.Text>Senha:</Styles.Text>
                <div className="password">
                  <InputData
                    width="27rem"
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                    value={data.password}
                    type={typeFild}
                  />

                  <div className="Icon" onClick={getPassword}>
                    <i className={icon}></i>
                  </div>
                </div>
              </Styles.ModalBodyInputs>
            </FormControl>
          </ModalBody>
          {error === true && (
            <MessageError
              colorScheme="blackAlpha"
              AlertTitle="Erro na inclusão!"
              AlertDescription="Verifique se todos os campos estão preenchidos!"
            />
          )}
          <ModalFooter>
            <Styles.ModalFooterButton>
              {!!loading && <Spinner />}
              <Styles.Button mr={3} onClick={CreateUser}>
                Salvar
              </Styles.Button>
              <Styles.Button mr={3} onClick={CallModal}>
                Cancelar
              </Styles.Button>
            </Styles.ModalFooterButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
