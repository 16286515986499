import styled from "styled-components";

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
`;

export const MainContainer = styled.div``;

export const Button = styled.button`
  background-color: #484e8d;
  border: none;
  border-radius: 0.25rem;
  width: 11rem;
  height: 3rem;
  color: white;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  transition: 0.5s;

  &:hover {
    background: #d03830;
  }

  &:first-child {
    background: #81ad21;
  }
`;

export const ModalFooterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
`;

export const ModalBodyInputs = styled.div`
  padding: 2rem;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Text = styled.div`
  color: white;
`;
