import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 420px;
  align-items: center;
  gap: 15px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 15rem;
  height: 70px;
  gap: 15px;
`;

export const Content = styled.div`
  background: #f1f1f1;
  display: flex;
  font-size: 16px;
  width: auto;
  max-height: 50px;
  text-align: start;
`;

export const Icons = styled.div``;
