import styled from "styled-components";

export const MainContainer = styled.div`
  width: 500px;
  transition: 0.4s;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .Accordion {
    @media (max-width: 767px) {
      & {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100%;
      }
    }
  }

  .AccordionItem {
    @media (max-width: 767px) {
      & {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
  }

  .AccordionButton {
    @media (max-width: 767px) {
      & {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export const Divider = styled.div`
  width: 30rem;

  border-bottom: 1 solid #d9d9d9;
`;

export const Text = styled.div`
  ${(props) => props}
`;

export const Button = styled.button`
  background-color: #484e8d;
  border: none;
  border-radius: 0.25rem;
  padding: 10px;
  color: white;
  width: 120px;
  margin-top: 1rem;
  transition: 0.2s;
  animation: linear;

  &:hover {
    background: #b6c767;
    color: black;
  }
`;
