import * as Styles from "./style.js";
import Axios from "axios";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ApexChartRadar = () => {
  const [typeScore, setTypeScore] = useState([]);

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    getTypeScore();
  }, []);

  const getTypeScore = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_URL_API}/typeScore`
    ).catch((err) => console.log(err));

    if (response) {
      setTypeScore(response.data);

      let chartData = [];
      let chartCategories = [];

      response.data.forEach((data) => {
        chartData.push(data.total_score);
        chartCategories.push(data.module);
      });

      const categories = {
        chart: {
          id: "radar",
          fontFamily: "Poppins",
        },
        stroke: {
          show: false,
        },
        markers: {
          size: 4,
          colors: "#FD6D4B",
        },
        fill: {
          colors: "#FD6D4B",
          opacity: 0.7,
        },
        dataLabels: {
          enabled: false,

          background: {
            enabled: true,
            borderRadius: 3,
          },
        },
        xaxis: {
          categories: chartCategories,
          labels: {
            show: true,
            style: {
              colors: [
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
              ],
            },
          },
        },
        yaxis: {
          show: false,
        },
      };

      const data = {
        name: "Pontos",
        data: chartData,
      };

      setOptions(categories);
      setSeries([data]);
    }
  };

  return (
    <Styles.Chart>
      <Chart options={options} series={series} type="radar" height={350} />
    </Styles.Chart>
  );
};

export default ApexChartRadar;
