import styled from "styled-components";

export const Icon = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  background-color: #484e8d;
  border: none;
  border-radius: 0.25rem;
  width: 11rem;
  height: 3rem;
  color: white;

  align-items: center;
  justify-content: space-evenly;
  display: flex;
  transition: 0.5s;

  &:hover {
    background: #d03830;
  }

  &:first-child {
    background: #81ad21;
  }
`;

export const ModalFooterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
`;

export const ModalBodyInputs = styled.div`
  padding: 2rem;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .password {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30rem;
    height: 42px;
  }

  .Icon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: #d9d9d9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: black;
      font-size: 25px;
    }
  }
`;

export const Text = styled.div`
  color: white;
`;
