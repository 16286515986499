import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 10vh;
  background: #6268a6;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 767px) {
    width: 100%;
    
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1440px;

  @media (max-width: 767px) {
    justify-content: space-around;
  }

  img {
    width: 200px;
    @media (max-width: 767px) {
      width: 170px;
    }
  }
`;

export const Contact = styled.button`
  border: none;
  margin: 0;
  background: #6268a6;
  transition: 0.4s;

  &:hover {
    color: black;
  }
`;

export const RestrictedArea = styled.div`
  border-right: 2px solid white;
  width: 8rem;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    color: black;
  }

  @media (max-width: 767px) {
    border: none;
    width: 150px;
  }
`;

export const UsersArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 20rem;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
  color: white;

  @media (max-width: 767px) {
    width: 150px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
`;
