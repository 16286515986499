import styled from "styled-components";

// export const Text = styled.div``;

export const MainContainer = styled.div`
  height: 70px;
  width: 100%;
  background: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
`;

export const Cnpj = styled.div`
  height: 100%;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Name = styled.div`
  height: 100%;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Status = styled.div`
  height: 100%;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .StatusColor {
    ${(props) => props}
    width: 130px;
    height: 60%;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const Date = styled.div`
  height: 100%;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Version = styled.div`
  height: 100%;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Config = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
