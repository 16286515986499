import styled from "styled-components";

export const Blocks = styled.button`
  height: 9rem;
  width: 9rem;
  background: #484e8d;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border: 0;

  &:hover {
    background: #91ad11;
    scale: 1.2;
  }

  i {
    font-size: 60px;
    color: white;
  }

  @media (max-width: 767px) {
    & {
      transition: 0.4s;
      height: 7rem;
      width: 7rem;
    }
  }
`;
export const Text = styled.div`
  color: white;
  font-size: 24px;
`;
