import * as Styles from "./style.js";

export const InputDataTextArea = (props) => {
  return (
    <Styles.MainContainer width={props.width}>
      <textarea
        placeholder={props.placeholder}
        onChange={(event) => props.onChange(event, props)}
        value={props.value}
      />
    </Styles.MainContainer>
  );
};
