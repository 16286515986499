import { Divider } from "@chakra-ui/react";
import {
  House,
  Key,
  MicrosoftExcelLogo,
  Question,
  SignOut,
  Table,
  User,
} from "phosphor-react";
import * as Styles from "./style.js";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const DashboardNavBar = () => {
  let navigate = useNavigate();

  const Logout = () => {
    Cookies.remove("email");
    Cookies.remove("name");
    navigate("/Login");
  };

  return (
    <Styles.NavBar>
      <Styles.ContentNavBar>
        <div className="home">
          <Styles.Button
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <div className="Icon">
              <House size={48} color="white" weight="duotone" />
            </div>
            <div className="Title">Home</div>
          </Styles.Button>
        </div>
        <Divider />

        <div className="options">
          <Styles.Button
            onClick={() => {
              navigate("/users");
            }}
          >
            <div className="Icon">
              <User size={48} color="white" weight="duotone" />
            </div>
            <div className="Title">Usuários</div>
          </Styles.Button>

          <Styles.Button
            onClick={() => {
              navigate("/licenses");
            }}
          >
            <div className="Icon">
              <Key size={48} color="white" weight="duotone" />
            </div>
            <div className="Title">Licenças</div>
          </Styles.Button>

          <Styles.Button
            onClick={() => {
              navigate("/content");
            }}
          >
            <div className="Icon">
              <Table size={48} color="white" weight="duotone" />
            </div>
            <div className="Title">Conteúdos</div>
          </Styles.Button>

          <Styles.Button
            onClick={() => {
              window.open("https://app-sheets-web.vercel.app/");
            }}
          >
            <div className="Icon">
              <MicrosoftExcelLogo size={48} color="white" weight="duotone" />
            </div>
            <div className="Title">Sheets</div>
          </Styles.Button>
        </div>

        <div className="singOut">
          <Styles.Button
            onClick={() => {
              navigate("/");
            }}
          >
            <div className="Icon">
              <Question size={48} color="white" weight="duotone" />
            </div>
            <div className="Title">FAQ</div>
          </Styles.Button>
          <Styles.Button onClick={Logout}>
            <div className="Icon">
              <SignOut size={48} color="white" weight="duotone" />
            </div>
            <div className="Title">Sair</div>
          </Styles.Button>
        </div>
      </Styles.ContentNavBar>
    </Styles.NavBar>
  );
};
