import { Select } from "@chakra-ui/react";
import * as Styles from "./style.js";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";

export const SelectModule = (props) => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    getTypes();
  }, []);

  useEffect(() => {}, [props]);

  const getTypes = async () => {
    const response = await Axios.get(`${process.env.REACT_APP_URL_API}/types`);

    if (response) {
      setTypes(response.data);
    }
  };
  return (
    <Styles.SelectSpace>
      <Select
        placeholder={
          props.module != null || undefined ? props.module : "--Selecione--"
        }
        bg="#d9d9d9"
        onChange={(event) => {
          props.onChange({ ...props.data, idModule: event.target.value });
        }}
        borderColor="transparent"
      >
        {types.map((response, key) => {
          const { id, module } = response;
          return (
            <option key={key} value={id}>
              {module}
            </option>
          );
        })}
      </Select>
    </Styles.SelectSpace>
  );
};
