import styled from "styled-components";

export const MainContainer = styled.div``;

export const Button = styled.button`
  background: linear-gradient(to right, #fb6d1d, #fc3a32);
  border: none;
  border-radius: 0.25rem;
  width: 15rem;
  height: 2.5rem;
  color: white;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  transition: 0.4s;

  &:hover {
    scale: 1.1;
  }
`;
