import * as Styles from "./style.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";

import { useState } from "react";
import { ListPlus } from "phosphor-react";
import Axios from "axios";
import { InputData } from "../../../../components/input/index.js";
import { SelectModule } from "../../../../components/select/index.js";
import { MessageError } from "../../../../components/messageError/index.js";

export const ModalCreateContent = () => {
  const [handleModal, setHandleModal] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const createContent = async () => {
    setLoading(true);
    if (
      data.title !== undefined &&
      data.description !== undefined &&
      data.video !== undefined &&
      data.idModule !== undefined
    ) {
      await Axios.post(`${process.env.REACT_APP_URL_API}/createContent`, {
        title: data.title,
        description: data.description,
        video: data.video,
        idModule: data.idModule,
      });
      setHandleModal(false);
      setError(false);
      setLoading(false);
      setData([]);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const CallModal = () => {
    if (handleModal === false) {
      setHandleModal(true);
      setData([]);
    } else {
      setHandleModal(false);
      setError(false);
      setData([]);
    }
  };

  return (
    <>
      <Styles.Icon onClick={CallModal}>
        <Tooltip label="Incluir conteúdo" placement="bottom" bg="#aeaeae">
          <ListPlus className="plus" size={48} color="black" weight="regular" />
        </Tooltip>
      </Styles.Icon>

      <Modal isCentered size={"xl"} isOpen={handleModal} onClose={CallModal}>
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent color="white" bg="blackAlpha.300">
          <ModalHeader>Criar conteúdo</ModalHeader>
          <ModalBody>
            <Styles.ModalBodyInputs>
              <Styles.Text>Titulo:</Styles.Text>
              <InputData
                width="30rem"
                onChange={(e) => setData({ ...data, title: e.target.value })}
                value={data.title}
              />
              <Styles.Text>Resumo:</Styles.Text>
              <InputData
                width="30rem"
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                value={data.description}
              />
              <Styles.Text>Link Externo:</Styles.Text>
              <InputData
                width="30rem"
                onChange={(e) => setData({ ...data, video: e.target.value })}
                value={data.video}
              />
              <Styles.Text>Modulos:</Styles.Text>
              <SelectModule
                onChange={setData}
                data={data}
                module={data.module}
              />
            </Styles.ModalBodyInputs>
          </ModalBody>
          {error === true && (
            <MessageError
              colorScheme="blackAlpha"
              AlertTitle="Erro na criação!"
              AlertDescription="Verifique se todos os campos estão preenchidos!"
            />
          )}
          <ModalFooter>
            <Styles.ModalFooterButton>
              {!!loading && <Spinner />}
              <Styles.Button mr={3} onClick={createContent}>
                Salvar
              </Styles.Button>
              <Styles.Button mr={3} onClick={CallModal}>
                Cancelar
              </Styles.Button>
            </Styles.ModalFooterButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
