import * as Styles from "./style.js";
import logo from "../../public/logo.svg";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  let navigate = useNavigate();

  const Contact = () => {
    window.open("https://softilux.com.br/");
  };

  const Restrict = () => {
    navigate("/Login");
  };

  return (
    <Styles.MainContainer>
      <Styles.Container>
        <img src={logo} alt="logo" />
        <Styles.UsersArea>
          <Styles.RestrictedArea onClick={Restrict}>
            Área restrita
          </Styles.RestrictedArea>
          <Styles.Contact onClick={Contact}>Entrar em contato</Styles.Contact>
        </Styles.UsersArea>
      </Styles.Container>
    </Styles.MainContainer>
  );
};
