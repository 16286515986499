import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import * as Styles from "./style.js";
import ModalVideo from "../modalVideo/index.js";

export const AccordionAnswer = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [close, setClose] = useState(true);
  const [paramsModal, setParamsModal] = useState(null);

  useEffect(() => {
    setOpenModal(false);
  }, []);

  const Playvideo = () => {
    const { video, title } = props;

    setParamsModal({ video, title });

    setOpenModal(true);
  };

  return (
    <Styles.MainContainer>
      <Accordion className="Accordion" allowToggle>
        <AccordionItem className="AccordionItem">
          <AccordionButton
            onClick={() => {
              if (close === true) {
                props.onClick(props.id);
                setClose(false);
              }
            }}
            className="AccordionButton"
            sx={{ border: 0, }}
          >
            <Box
              fontSize="14px"
              as="span"
              flex="1"
              p="1"
              pl="20px"
              textAlign="left"
            >
              {props.title}
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel
            pb={5}
            sx={{ display: "flex", flexDirection: "column", background: "#e8e8e8" }}
          >
            {props.text}
            <Styles.Button onClick={Playvideo}>Clique aqui!</Styles.Button>
            {openModal && (
              <ModalVideo
                isOpen={openModal}
                setOpenModal={setOpenModal}
                video={paramsModal.video}
                videoTitle={paramsModal.title}
              />
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Styles.Divider />
    </Styles.MainContainer>
  );
};
